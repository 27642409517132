import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
/* eslint-disable @typescript-eslint/naming-convention */

export enum PAGES {
  EXAMPLES = 'examples',
  MY_EXAMPLES = 'my-examples',
  MORE = 'more',
  ERROR = 'error',
  ERROR_404 = 'not-found',
  HOME = 'home',
  LOGIN = 'login',
  MODEL = 'model',
  PERSONAL_DATA = 'personal-data',
  LANGUAGE = 'language',
  ITEM = 'publication',
  ITEM_EXAMPLE = 'example',
  PUBLICATIONS = 'publications',
  PUBLICATIONS_ORIGINAL = 'mercadona-publications',
  RESULTS = 'search-results',
  AUTHENTICATION = 'auth',
  MOBILE_WEB_LANDING = 'mobileweb-landing',
  PRIVACY = 'privacy',
  VIEWER = 'viewer',
  PUBLIC_HISTORY = 'history',
  PUBLIC_MANAGE_MODEL = 'manage-model',
  PUBLIC_SUSTAINABLE = 'sustainable',
  TERMS_AND_CONDITIONS_INTER = 'terms-and-conditions-inter',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  BANNED = 'banned',
  MAINTENANCE = 'maintenance',
  OFFLINE = 'offline',
  NOTIFICATIONS = 'notifications',
  SEARCH_RESULTS = 'search-results',
  COOKIES = 'cookies',
  CONTACT_US = 'contact-with-us',
  DEVICE_INFO = 'device-info',
  DATA_TREATMENT = 'data-treatment',
  LOGOUT = 'logout',
  CODES = 'codes',
  CLOSE_APP = 'close-app',
  EMAIL_EDITION = 'email-edition',
  ADDRESS_EDITION = 'address-edition',
  PHONE_EDITION = 'phone-edition',
  LANGUAGES = 'languages',
  STUDIES = 'studies',
  BEHAVIOUR_CODE = 'behaviour-code',
  LOGISTIC_INFO = 'logistic-anual-calendar',
  CONFIGURATION = 'configuration',
  OFFICESERVICES = 'office-service',
  MY_PUBLICATIONS = 'my-publications',
  ITEM_MY_PUBLICATION = 'my-publication',
  CREATE_PUBLICATION = 'create-publication',
  OFFICE_FLOOR_PLAN = 'office_floor_plan',
  ON_BOARDING = 'on-boarding',
  VACATIONS_REQUEST = 'manage-vacations',
  VACATIONS = 'vacations',
  VACATIONS_DETAIL = 'vacations-detail'
}

export const DETAIL_PAGES_PARENT = {
  publication: PAGES.PUBLICATIONS,
  example: PAGES.EXAMPLES
};

export enum MOT_PAGES {
  SCHEDULE = 'schedule',
  SCHEDULE_OBSERVATIONS = 'schedule-observations'
}

export enum PTRAB_PAGES {
  CERTIFICATES = 'certificates',
  PAYSLIPS = 'payslips',
  DIRECT_DEBIT_EDITION = 'direct-debit-edition',
  PERSONAL_INCOME_TAX = 'irpf',
  PERSONAL_INCOME_TAX_INFO = 'irpf-info',
  WITHHOLDING_EDITION = 'withholding-edition',
  WITHHOLDING_EDITION_PT = 'withholding-edition-pt',
  IRPF_WORKER_PAGE = 'worker',
  IRPF_WORKER_PT_PAGE = 'worker-pt',
  IRPF_ANCESTORS = 'ancestors',
  IRPF_DEPENDANTS = 'dependants',
  IRPF_DEPENDANTS_DETAIL = 'detail',
  IRPF_SPOUSE = 'spouse',
  IRPF_MORTGAGE_REDUCTION = 'mortgage-reduction',
  IRPF_CEUTA_MELILLA_RESIDENCE = 'ceuta-melilla-residence',
  IRPF_DESCENDANTS = 'descendants',
  IRPF_PENSION_PAYMENT = 'pensions',
  IRPF_YOUNG_WITHHOLDING = 'young-withholding',
  PTRAB_LOGIN = 'login',
  INCOME_AND_ASSETS = 'income-and-assets',
  RISKY_PREGNANCY = 'risky-pregnancy'
}

export const PTRAB_SECTIONS = [
  PTRAB_PAGES.DIRECT_DEBIT_EDITION,
  PTRAB_PAGES.WITHHOLDING_EDITION,
  PTRAB_PAGES.WITHHOLDING_EDITION_PT
];

export const ROOT_PAGES = {
  AUTHENTICATION: PAGES.AUTHENTICATION,
  LOGIN: PAGES.LOGIN,
  MOBILE_WEB_LANDING: PAGES.MOBILE_WEB_LANDING,
  PERSONAL_INCOME_TAX_INFO: PTRAB_PAGES.PERSONAL_INCOME_TAX_INFO,
  PRIVACY: PAGES.PRIVACY,
  VIEWER: PAGES.VIEWER
};

export const PAGES_WITHOUT_NOTIFICATIONS = [
  PAGES.MY_EXAMPLES,
  PAGES.TERMS_AND_CONDITIONS_INTER,
  PAGES.TERMS_AND_CONDITIONS,
  PAGES.CONTACT_US,
  PAGES.COOKIES,
  PAGES.DEVICE_INFO
];

export const DEFAULT_PAGE = PAGES.AUTHENTICATION;
export const DEFAULT_PAGE_WHEN_LOGGED = PAGES.HOME;

export const SHAREABLE_LINK_ROUTES: MSafeAny = {
  e: PAGES.ITEM_EXAMPLE,
  p: PAGES.ITEM
};

export const WEBVIEW_LINK_ROUTES = [PAGES.MODEL, PTRAB_PAGES.PERSONAL_INCOME_TAX_INFO];

export const PUBLIC_PAGES = [
  PAGES.AUTHENTICATION,
  PAGES.LOGIN,
  PAGES.MOBILE_WEB_LANDING,
  PAGES.PRIVACY,
  PAGES.VIEWER,
  PAGES.PUBLIC_HISTORY,
  PAGES.PUBLIC_MANAGE_MODEL,
  PAGES.PUBLIC_SUSTAINABLE,
  PAGES.CODES,
  PAGES.LOGOUT,
  PTRAB_PAGES.PERSONAL_INCOME_TAX_INFO
];

export const ERROR_PAGES = [PAGES.ERROR, PAGES.ERROR_404, PAGES.BANNED, PAGES.MAINTENANCE, PAGES.OFFLINE];

export const PRIVATE_PTRAB_PAGES = [
  PTRAB_PAGES.CERTIFICATES,
  PTRAB_PAGES.PAYSLIPS,
  PTRAB_PAGES.DIRECT_DEBIT_EDITION,
  PTRAB_PAGES.PERSONAL_INCOME_TAX,
  PTRAB_PAGES.WITHHOLDING_EDITION,
  PTRAB_PAGES.WITHHOLDING_EDITION_PT,
  PTRAB_PAGES.IRPF_WORKER_PAGE,
  PTRAB_PAGES.IRPF_WORKER_PT_PAGE,
  PTRAB_PAGES.IRPF_ANCESTORS,
  PTRAB_PAGES.IRPF_DEPENDANTS,
  PTRAB_PAGES.IRPF_DEPENDANTS_DETAIL,
  PTRAB_PAGES.IRPF_SPOUSE,
  PTRAB_PAGES.IRPF_DESCENDANTS,
  PTRAB_PAGES.IRPF_MORTGAGE_REDUCTION,
  PTRAB_PAGES.IRPF_CEUTA_MELILLA_RESIDENCE,
  PTRAB_PAGES.IRPF_PENSION_PAYMENT,
  PTRAB_PAGES.INCOME_AND_ASSETS,
  PTRAB_PAGES.RISKY_PREGNANCY,
  PAGES.PERSONAL_DATA,
  PAGES.EMAIL_EDITION,
  PAGES.ADDRESS_EDITION,
  PAGES.PHONE_EDITION
];

export const PREVIOUS_PAGE = {
  example: PAGES.EXAMPLES
};
