import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';

import { PTRAB_SECTIONS_PERMISSIONS } from '@app/ptrab/ptrab-permissions.const';
import { Segment } from '@app/shared/interfaces/segments/segment.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { UserConfiguration } from '@app/shared/models/user/user-configuration.model';
import { flatten } from '@app/shared/utils/utils';
import { ApiUrls } from '@services/api/api.urls.service';
import { AuditService } from '@services/audit/audit.service';
import { TokenHelperService } from '@services/auth/token.helper.service';
import { AppError, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { Logger } from '@services/logger/logger.service';
import { StorageService } from '@services/storage/storage.service';
import { ENCTYPE } from '@shared/constants/enctype/enctype.constants';
import { Permissions } from '@shared/enums/permissions/permissions.enum';
import { PLATFORM } from '@shared/enums/platform/platform.enum';
import { FileFetchHelper } from '@shared/helpers/file-fetch/file-fetch.helper';
import { AuditCodes, AuditLevels, AuditMessage } from '@shared/models/audit-message/audit-message';
import { Conditions, NewsItem } from '@shared/models/conditions/conditions.model';
import { ContactRequestData } from '@shared/models/message/contact.request.model';
import { IMessage, Message } from '@shared/models/message/message.model';
import { RepresentedUser } from '@shared/models/user/represented-user.model';
import { User } from '@shared/models/user/user.model';
import { ENV } from 'src/environments/environment';
import { OnBoarding } from '@app/shared/models/user/user-onboarding.model';
import { OnBoardingResponse } from '@app/shared/interfaces/user/user-onboarding.interface';
import { isAndroid, isIOS } from '@shared/utils/is-tablet.util';
import { UserLogin, UserLoginPhoneResponse } from '@app/shared/interfaces/user/user-login.interface';

/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly logger = new Logger('UserService');
  private formatTypes = ['data:application/octet-stream;', 'data:text/plain;', 'data:;'];
  private devicePlatform!: string;

  private userInfo: User | undefined;
  private userInfoSubject = new ReplaySubject<User>(1);
  userInfoChanges$: Observable<User> = this.userInfoSubject.asObservable();

  constructor(
    private http: HttpClient,
    private urls: ApiUrls,
    private tokenHelperService: TokenHelperService,
    private storageService: StorageService,
    private auditService: AuditService,
    private errorService: ErrorService
  ) {
    this.getStoredUser();
    this.setDevicePlatform();
  }

  setDevicePlatform() {
    if (isAndroid()) {
      this.devicePlatform = PLATFORM.ANDROID;
    } else if (isIOS()) {
      this.devicePlatform = PLATFORM.IOS;
    } else {
      this.devicePlatform = PLATFORM.DESKTOP;
    }
  }

  clear() {
    this.userInfo = undefined;
    this.userInfoSubject.next(this.userInfo as MSafeAny);
  }

  async getUserId(): Promise<string | null> {
    const storedUser = await this.getStoredUser();
    return storedUser && this.userInfo ? this.userInfo.userid : null;
  }

  async getInternalUserId(): Promise<string | null> {
    const storedUser = await this.getStoredUser();
    return storedUser && this.userInfo && this.userInfo.internal_user_id ? this.userInfo.internal_user_id : null;
  }

  async getPermissions(): Promise<Permissions[]> {
    const storedUser = await this.getStoredUser();
    return storedUser && this.userInfo ? this.userInfo.permissions : [];
  }

  async getLegalConditionsType(): Promise<string | null> {
    const storedUser = await this.getStoredUser();
    return storedUser && this.userInfo && this.userInfo.legalConditionsType ? this.userInfo.legalConditionsType : null;
  }

  async getStoredUser(): Promise<User> {
    if (!this.userInfo) {
      return this.storageService
        .getUserData()
        .then((user: User) => {
          this.userInfo = user;
          this.userInfoSubject.next(this.userInfo);
          return Promise.resolve(this.userInfo);
        })
        .catch(() => {
          return Promise.reject(undefined);
        });
    }
    return Promise.resolve(this.userInfo);
  }

  hasPermissions(): boolean {
    return Boolean(this.userInfo && this.userInfo.permissions && this.userInfo.permissions.length > 0);
  }

  hasPermissionDownloadPdf(): boolean {
    return Boolean(this.userInfo && this.userInfo.permissions.includes(Permissions.MOT_VACATIONS_PDF));
  }

  sectionHasPermission(section: string): boolean {
    if (this.userInfo && this.userInfo.permissions.find((element) => element === section)) {
      return true;
    }
    return false;
  }

  hasAccessToPtrabSections(): boolean {
    if (ENV.fullAccessPtrab) {
      return true;
    }

    const ptrabPermissions = flatten(Object.values(PTRAB_SECTIONS_PERMISSIONS));
    const hasSomePermissions = this.userInfo?.permissions.some((permission) => ptrabPermissions.includes(permission));

    return Boolean(hasSomePermissions);
  }

  async requestUserInfo(): Promise<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-language': navigator.language.substring(0, 2)
      })
    };

    const newUserInfo: MSafeAny = await this.http.post<User>(this.urls.user.info, {}, httpOptions).toPromise();
    newUserInfo.photo = await this.preloadUserPhoto(newUserInfo.photo);
    this.userInfo = newUserInfo;
    return this.storeUser(true);
  }

  async syncImage(): Promise<string> {
    const response: MSafeAny = await this.http.get(this.urls.user.image_database, {}).toPromise();

    if (this.userInfo) {
      this.userInfo.photo = await this.preloadUserPhoto(response.photo);
      this.storeUser();

      return Promise.resolve(this.userInfo.photo);
    }
    return Promise.resolve('');
  }

  async checkData() {
    const storageUser = await this.storageService.getUserData();

    if (!storageUser || storageUser.userid !== this.userInfo?.userid) {
      this.userInfo = storageUser;
      return this.errorService.add(new AppError(ErrorTypes.CLOSE_APP));
    }
  }

  getRepresentedUsers(): Observable<RepresentedUser[]> {
    return this.http
      .get(this.urls.user.representedUsers)
      .pipe(map((users: MSafeAny) => users.map((user: MSafeAny) => new RepresentedUser(user))));
  }

  getContactMessages(): Observable<Message[]> {
    return this.http
      .get<IMessage[]>(this.urls.user.contact)
      .pipe(map((dataList: IMessage[]) => dataList.map((data) => new Message(data))));
  }

  setAcceptConditions(email: string, acceptation: boolean): Observable<MSafeAny> {
    return this.http.post(this.urls.user.legalconditions, { email, acceptation }).pipe(
      tap(() => {
        if (this.userInfo) {
          this.userInfo.acceptLegal = acceptation;
        }
        this.storeUser();
      })
    );
  }

  editAvatar(newAvatar: File): Observable<MSafeAny> {
    const formData = new FormData();
    formData.append('image', newAvatar);
    formData.append('mime_type', newAvatar.type);
    const httpOptions = {
      headers: new HttpHeaders({
        enctype: ENCTYPE.BOUNDARY
      })
    };
    return this.http.post(this.urls.user.editAvatar, formData, httpOptions);
  }

  deleteAvatar(): Observable<MSafeAny> {
    return this.http.delete(this.urls.user.editAvatar);
  }

  storeAvatar(b64Image: string): Promise<User> {
    this.userInfo = this.userInfo ?? ({} as User);
    this.userInfo.photo = b64Image;
    return this.storeUser();
  }

  async setLanguage(language_code: string, language_country: string): Promise<User> {
    return this.http
      .post(this.urls.user.language, { language_code, language_country })
      .toPromise()
      .then(() => {
        return this.storeLanguage(language_code, language_country);
      })
      .catch((error) => {
        const message = `Error trying to change user language code: ${language_code}, country: ${language_country}`;
        this.logger.error(message);
        this.logger.error(error);
        return Promise.reject(message);
      });
  }

  storeLanguage(language_code: string, language_country?: string): Promise<User> {
    this.userInfo = this.userInfo ?? ({} as User);
    this.userInfo.language_code = language_code;
    this.userInfo.language_country = language_country ?? '';
    return this.storeUser();
  }

  getLanguageCode(): string {
    this.userInfo = this.userInfo ?? ({} as User);
    return this.userInfo.language_code;
  }

  postContact(data: ContactRequestData): Observable<MSafeAny> {
    return this.http.post(this.urls.user.contact, data);
  }

  updateUserConditions(): Observable<User | undefined> {
    const bodyParams = {
      devicePlatform: this.devicePlatform
    };

    return this.http.post<Conditions>(this.urls.user.conditions, bodyParams).pipe(
      map((conditions: Conditions) => {
        this.mergeUser(conditions);
        this.storeUser();
        return this.userInfo;
      })
    );
  }

  setAcceptNews(newsItem: NewsItem): Observable<MSafeAny> {
    newsItem.accepted = true;
    return this.http.post(this.urls.user.acceptNews, newsItem).pipe(
      tap(() => {
        const acceptedNew = this.userInfo?.news?.find((item) => item.type === newsItem.type);

        if (acceptedNew) {
          acceptedNew.accepted = true;
        }
        this.storeUser();
      })
    );
  }

  async mergeUser(user: User | Conditions) {
    this.userInfo = {
      ...this.userInfo,
      ...(user as User),
      isDiscontinousFixed: user.permissions?.includes(Permissions.DISCOUNTINOUS_FIXED)
    };
  }

  private async preloadUserPhoto(photo: string) {
    if (photo) {
      try {
        const b64Photo: string = await FileFetchHelper.getB64FromUrl(photo);
        const format = this.formatTypes.find((type) => b64Photo.indexOf(type) > -1);
        return b64Photo.replace(format ?? '', 'data:image/jpeg;');
      } catch {
        this.logger.error('Cannot retrieve user photo');
      }
    }
    return '';
  }

  private async storeUser(audit = false): Promise<User> {
    const hasAdfsSession = await this.tokenHelperService.hasAdfsSession();
    if (!hasAdfsSession) {
      return Promise.reject('User not logged');
    }

    await this.storageService.storeUserData(this.userInfo as User);

    this.userInfoSubject.next(this.userInfo as User);
    if (audit) {
      await this.auditService.push(
        new AuditMessage({
          level: AuditLevels.Info,
          message: `storeUser success, userInfo is: ${this.userInfo?.name}, ${this.userInfo?.userid}`,
          status_code: AuditCodes.Success,
          app_component: 'UserService'
        })
      );
    }

    return Promise.resolve(this.userInfo as User);
  }

  public getUserConfiguration(): Observable<UserConfiguration> {
    return this.http
      .get<UserConfiguration>(this.urls.user.user_configuration)
      .pipe(map((response) => new UserConfiguration(response)));
  }

  public updateUserConfiguration(userInfoChange: MSafeAny): Observable<UserConfiguration> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // XXX: There is a super strange problem due to the autogenerated boundary of the browser, force it
        // do not delete this!
        enctype: ENCTYPE.BOUNDARY
      })
    };
    return this.http
      .put(this.urls.user.user_configuration, userInfoChange, httpOptions)
      .pipe(map((response) => new UserConfiguration(response)));
  }

  getUserSegments(hideEmpty?: boolean): Observable<Segment[]> {
    let params = new HttpParams();

    if (hideEmpty) {
      params = params.append('empty_segment', 'false');
    }

    return this.http.get<Segment[]>(this.urls.user.segments, { params }).pipe<MSafeAny>(pluck('segments'));
  }

  getOnBoarding(): Observable<OnBoarding> {
    return this.http.get<OnBoardingResponse>(this.urls.user.onBoarding).pipe(map((data) => new OnBoarding(data)));
  }

  updateOnBoarding(): Observable<OnBoarding> {
    return this.http.post<MSafeAny>(this.urls.user.onBoarding, {});
    // .pipe(map((data) => new OnBoarding(data)));
  }

  getUserLogin(userId: string): Observable<UserLogin> {
    let params = new HttpParams();

    params = params.append('user_id', userId);

    return this.http.get<UserLogin>(this.urls.user.login, { params });
  }

  checkUserLoginPhone(userId: string, phone: string): Observable<UserLoginPhoneResponse> {
    return this.http.post<UserLoginPhoneResponse>(this.urls.user.phone_check, { user_id: userId, phone });
  }
}
