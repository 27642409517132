import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { from, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { TokenCollection, TokenHelperService } from '@services/auth/token.helper.service';
import { AppError, ErrorCodes, ErrorMessages, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { Logger } from '@services/logger/logger.service';
import { NetworkService } from '@services/network/network.service';
import { ENV } from 'src/environments/environment';

import { HandlerErrorService } from '../handler-error/handler-error.service';
import { appVersion } from '@shared/utils/utils';

/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  private logger = new Logger('Api Interceptor');

  private readonly VIMEO_EXCEPTION = /files\.tus\.vimeo\.com/;

  private readonly URL_EXCEPTIONS = [
    this.VIMEO_EXCEPTION,
    /latest\.json$/,
    /oauth2\/token/,
    /health-check/,
    /video\/public/,
    /assets\/i18n\/[\w]+\.json$/
  ];
  private readonly URLS_NO_CACHE = [/health-check/];

  private authService: AuthService;

  version: string = appVersion;

  constructor(
    private token: TokenHelperService,
    private network: NetworkService,
    private errorService: ErrorService,
    private translate: TranslateService,
    private handlerErrorService: HandlerErrorService,
    private injector: Injector
  ) {
    this.authService = this.injector.get(AuthService);
  }

  intercept(request: HttpRequest<MSafeAny>, next: HttpHandler): Observable<HttpEvent<MSafeAny>> {
    return from(this.prepareToken(request)).pipe(
      switchMap((token: MSafeAny) => {
        this.logger.info('token ready', token);

        if (!this.skip(request) && token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.token}`,
              'Content-Language': this.translate.currentLang
            }
          });
          this.logger.info(`token added to request: ${request.url} --> headers:`, request.headers);
        } else {
          if (this.URLS_NO_CACHE.some((regexp) => regexp.test(request.url))) {
            request = request.clone({
              setHeaders: {
                'Cache-Control': 'no-cache'
              }
            });
          }
          this.logger.warn(`Request url is "${request.url}", which seems to be an exception. Skip token injection...`);
        }

        if (!this.skipOne(request, this.VIMEO_EXCEPTION)) {
          request = request.clone({
            headers: request.headers.set('App-Version', this.version).set('platform', 'web')
          });
        }

        return next.handle(request).pipe(catchError(this.handleError));
      }),
      catchError((switchMapError) => {
        this.logger.error('HttpRequest error:', switchMapError);
        return this.handlerErrorService.handleError(switchMapError, request);
      })
    );
  }

  private async prepareToken(request: HttpRequest<MSafeAny>) {
    if (this.skip(request)) {
      this.logger.debug('prepareToken: skip token');
      return Promise.resolve();
    }

    const tokenCollection = await this.token.getCollection();
    if (tokenCollection.token && !ENV.isMocksMode) {
      this.logger.debug('prepareToken: tokenCollection has token');
      return this.getToken(tokenCollection);
    }

    return Promise.resolve(undefined);
  }

  private async getToken(tokenCollection: TokenCollection) {
    if (this.token.isTokenExpired(tokenCollection.expiration)) {
      this.logger.info('getToken get expired token');
      return this.onExpiration(tokenCollection);
    }

    this.logger.info('does not need refresh token', tokenCollection);
    return Promise.resolve(tokenCollection);
  }

  private async onExpiration(tokenCollection: TokenCollection) {
    this.logger.info('token expired');
    this.logger.info(tokenCollection);

    if (
      this.token.isTokenExpired(tokenCollection.refreshTokenExpiration) ||
      (this.errorService.lastError && this.errorService.lastError.type === ErrorTypes.REFRESH_TOKEN)
    ) {
      this.logger.debug('refreshToken expired');
      this.errorService.add(new AppError(ErrorTypes.REFRESH_TOKEN, ''));
      return Promise.reject('it needs login again');
    }

    this.logger.info('refresh token', tokenCollection);

    try {
      this.logger.debug(
        `Trying to get new token: refreshToken: ${tokenCollection.refreshToken} --> token: ${tokenCollection.token}`
      );
      const renewToken = await this.authService.renewToken(tokenCollection);
      return renewToken.privateToken;
    } catch (error) {
      return Promise.reject(`Unable to renew token --> ${JSON.stringify(error)}`);
    }
  }

  private handleError = (error) => {
    this.logger.error(error);
    if (!this.network.hasConnection()) {
      this.handleNoConnection();
      return throwError(error);
    }

    switch (error?.status) {
      case ErrorCodes.UPGRADE_REQUIRED:
        this.handleUpgradeVersion();
        break;
      case ErrorCodes.MAINTENANCE:
        this.errorService.add(new AppError(ErrorTypes.MAINTENANCE_MODE, error));
        break;
      case ErrorCodes.FORBIDDEN:
        this.handleForbidden(error);
        break;
      case ErrorCodes.UNAUTHORIZED:
        this.handleUnauthorized();
        break;
      default:
        if (error.status === 0 && this.token) {
          this.logger.info('HANDLER ERROR status 0', error);
        } else if (error instanceof TimeoutError) {
          this.handleTimeout();
        }
    }
    return throwError(error);
  };

  private handleNoConnection() {
    this.logger.info('No Internet error');
  }

  private handleTimeout() {
    this.logger.info('Timeout error');
  }

  private handleUnauthorized() {
    this.logger.info('Session has expired or unauthorized');
  }

  private handleForbidden(error) {
    this.logger.info('Not enough permissions');
    if (error.error.code === ErrorMessages.USER_BANNED) {
      this.errorService.add(new AppError(ErrorTypes.USER_BANNED, error));
    }
  }

  private skipOne(request: HttpRequest<MSafeAny>, regexp: RegExp) {
    return regexp.test(request.url);
  }

  private skip(request: HttpRequest<MSafeAny>) {
    return this.URL_EXCEPTIONS.some((regexp) => regexp.test(request.url));
  }

  private handleUpgradeVersion() {
    this.logger.info('outdated version');
  }
}
