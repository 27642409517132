import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { ApiUrls } from '@services/api/api.urls.service';

import { Logger } from '../logger';
import { ENV } from 'src/environments/environment';
import { TokenCollection } from './token.helper.service';

export interface Token {
  token: string;
  expiration: number;
}

export enum SessionTokenTypes {
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  ACCESS_TOKEN = 'ACCESS_TOKEN'
}
export interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  resource: string;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
  id_token: string;
}

export interface AzureAccessTokenResponse {
  access_token: string;
  expires_in: number;
  ext_expires_in: number;
  refresh_token: string;
  id_token: string;
  token_type: string;
  scope: string;
}

export interface BearerToken {
  refreshToken: string;
  refreshTokenExpiration: number;
  privateToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private logger = new Logger('token provider');
  private currentRefreshPromise!: Promise<BearerToken> | null;
  private currentRenewPromise!: Promise<BearerToken> | null;
  private currentExpiredToken!: string;

  constructor(private http: HttpClient, private urls: ApiUrls) {}

  getRefreshToken(code: string): Promise<BearerToken> {
    if (this.currentRefreshPromise) {
      this.logger.debug('Attempting to retrieve the same refresh token request, returning cached promise...');
      return this.currentRefreshPromise;
    }

    this.logger.debug('getRefreshToken before http request', code);

    const body = new URLSearchParams();
    body.set('code', code);
    body.set('grant_type', 'authorization_code');
    body.set('client_id', ENV.authentication.clientId);
    body.set('scope', 'https://graph.microsoft.com/default');
    body.set('redirect_uri', ENV.authentication.redirectUri);
    body.set('resource', ENV.authentication.resource);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    this.currentRefreshPromise = lastValueFrom(
      this.http.post<AccessTokenResponse>(this.urls.adfs.token, body, httpOptions).pipe(
        finalize(() => {
          this.logger.debug('getRefreshToken reset saved promise', code);
          this.currentRefreshPromise = null;
        }),
        map((data) => {
          this.logger.info('getRefreshToken response data', data);

          const token: BearerToken = {
            refreshToken: data.refresh_token,
            refreshTokenExpiration: data.refresh_token_expires_in,
            privateToken: data.access_token
          };

          return token;
        }),
        catchError((error) => throwError(() => error))
      )
    );

    return this.currentRefreshPromise;
  }

  getAzureRefreshToken(code: string): Promise<BearerToken> {
    if (this.currentRefreshPromise) {
      this.logger.debug('Attempting to retrieve the same refresh token request, returning cached promise...');
      return this.currentRefreshPromise;
    }

    this.logger.debug('AZURE >> getRefreshToken before http request', code);

    const body = new URLSearchParams();
    body.set('code', code);
    body.set('grant_type', 'authorization_code');
    body.set('client_id', ENV.azure.clientId);
    body.set('code_verifier', localStorage.getItem('code_verifier') || '');
    body.set('scope', 'openid');
    body.set('redirect_uri', ENV.azure.redirectUri);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    this.currentRefreshPromise = lastValueFrom(
      this.http.post<AzureAccessTokenResponse>(this.urls.azure.token, body, httpOptions).pipe(
        finalize(() => {
          this.logger.debug('Azure => GetRefreshToken reset saved promise', code);
          this.currentRefreshPromise = null;
        }),
        map((data) => {
          this.logger.info('getRefreshToken response data', data);

          const token: BearerToken = {
            refreshToken: data.refresh_token,
            refreshTokenExpiration: data.ext_expires_in,
            privateToken: data.access_token
          };

          return token;
        }),
        catchError((error) => throwError(() => error))
      )
    );

    return this.currentRefreshPromise;
  }

  resetRenewPromise() {
    this.currentRenewPromise = null;
  }

  renewToken(tokenCollection: TokenCollection): Promise<BearerToken> {
    if (tokenCollection.token !== this.currentExpiredToken) {
      this.logger.debug('Expired token is different to current expired token, removing current request');
      this.currentRenewPromise = null;
    }

    if (this.currentRenewPromise) {
      this.logger.debug('Attempting to retrieve the same renew token request, returning cached promise...');
      return this.currentRenewPromise;
    }

    this.logger.debug('renewToken before http request');
    this.currentExpiredToken = tokenCollection.token;

    const body = new URLSearchParams();
    body.set('refresh_token', tokenCollection.refreshToken);
    body.set('grant_type', SessionTokenTypes.REFRESH_TOKEN.toLowerCase());
    body.set('client_id', ENV.authentication.clientId);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    this.currentRenewPromise = lastValueFrom(
      this.http.post<AccessTokenResponse>(this.urls.adfs.token, body, httpOptions).pipe(
        finalize(() => {
          this.logger.debug('renewToken finalize');
          this.currentRenewPromise = null;
        }),
        map((data) => {
          this.logger.info('request for renew token', data);

          const token: BearerToken = {
            refreshToken: data.refresh_token,
            refreshTokenExpiration: data.refresh_token_expires_in,
            privateToken: data.access_token
          };

          return token;
        }),
        catchError((error) => throwError(() => error))
      )
    );

    return this.currentRenewPromise;
  }
}
