import { Injectable } from '@angular/core';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { isNullOrUndefined } from '@app/shared/utils/utils';
import { Logger } from '@services/logger/logger.service';
import { STORAGE_CONSTANTS, StorageService } from '@services/storage/';
import { ENV } from 'src/environments/environment';

export interface TokenCollection {
  token: string;
  refreshToken: string;
  expiration: number;
  refreshTokenExpiration: number;
}

@Injectable({
  providedIn: 'root'
})
export class TokenHelperService {
  private logger = new Logger('apiTokenProvider');
  constructor(private storage: StorageService) {}

  async get() {
    return this.getMockValue('Token') || this.storage.get(STORAGE_CONSTANTS.TOKEN);
  }

  async getRefreshToken() {
    return this.getMockValue('RefreshToken') || this.storage.get(STORAGE_CONSTANTS.REFRESH_TOKEN);
  }

  async hasAdfsSession(): Promise<boolean> {
    const token = await this.get();
    return !isNullOrUndefined(token);
  }

  async getExpiration() {
    return this.getMockValue('Expiration') || this.storage.get(STORAGE_CONSTANTS.EXPIRATION);
  }

  async getRefreshTokenExpiration() {
    return this.getMockValue('Refresh') || this.storage.get(STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION);
  }

  isTokenExpired(date: number) {
    return date < this.now();
  }

  async getCollection() {
    const [token, refreshToken, expiration, refreshTokenExpiration] = await Promise.all([
      this.get(),
      this.getRefreshToken(),
      this.getExpiration(),
      this.getRefreshTokenExpiration()
    ]);

    this.logger.info('get collection', {
      token,
      refreshToken,
      expiration,
      refreshTokenExpiration
    });

    return {
      token,
      refreshToken,
      expiration,
      refreshTokenExpiration
    };
  }

  set(token: MSafeAny) {
    this.storage.set(STORAGE_CONSTANTS.TOKEN, token);
  }

  remove() {
    this.storage.remove(STORAGE_CONSTANTS.TOKEN);
  }

  now() {
    return Math.round(new Date().getTime() / 1000.0);
  }

  private getMockValue(value: string): string | false {
    const strToEncode = `{
      "upn": "${value}@"
    }`;
    return ENV.isMocksMode && `mock.${btoa(strToEncode)}`;
  }
}
