export interface UserLogin {
  role: UserLoginRole;
  phones: string[];
}

export interface UserLoginPhoneResponse {
  phone: string;
}

export enum UserLoginRole {
  EMPLOYEE = 'employee',
  EXEMPLOYEE = 'exemployee'
}
